import React from "react";
import { Card } from "antd";

const CustomCard = (props) => {
  const cardStyle = {
    borderRadius: "8px",
    border: "1px solid #d0d5dd",
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
    ...props.style, // Allow external styles to override if provided
  };

  const cardBodyStyle = {
    ...props.bodyStyle, // Allow external body styles to override if provided
  };

  return (
    <Card
      {...props}
      style={cardStyle}
      bodyStyle={cardBodyStyle}
      styles={{ header: { width: "100%", textAlign: "left" } }}
    >
      {props.children}
    </Card>
  );
};

export default CustomCard;
