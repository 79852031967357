import React from "react";
import CustomCard from "../SOP/CustomCard";
import { Table } from "antd";
import axios from "axios";

const FMEA = ({ assetDetails, assetParts }) => {
  const columns = [
    {
      title: "Asset Component",
      dataIndex: "Asset Component",
      key: "Asset Component",
    },
    {
      title: "Potential Failure Mode",
      dataIndex: "Potential Failure Mode",
      key: "Potential Failure Mode",
    },
    {
      title: "Potential Failure Effects",
      dataIndex: "Potential Failure Effects",
      key: "Potential Failure Effects",
    },
    {
      title: "Severity Scale",
      dataIndex: "Severity Scale",
      key: "Severity Scale",
    },
    {
      title: "Potential Causes",
      dataIndex: "Potential Causes",
      key: "Potential Causes",
    },
    {
      title: "Expected Frequency",
      dataIndex: "Expected Frequency",
      key: "Expected Frequency",
    },
    {
      title: "Current Process Controls",
      dataIndex: "Current Process Controls",
      key: "Current Process Controls",
    },
    {
      title: "Detection Scale",
      dataIndex: "Detection Scale",
      key: "Detection Scale",
    },
    {
      title: "Risk Priority Number (RPN)",
      dataIndex: "Risk Priority Number (RPN)",
      key: "Risk Priority Number (RPN)",
    },
    {
      title: "Recommended Actions",
      dataIndex: "Recommended Actions",
      key: "Recommended Actions",
    },
  ];

  return (
    <CustomCard title="FMEA" bodyStyle={{ padding: "0px" }}>
      <Table dataSource={assetParts} columns={columns} />
    </CustomCard>
  );
};

export default FMEA;
