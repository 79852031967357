import { ConfigProvider, Segmented } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const TypeSegemnted = ({ viewType, setViewType }) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Segmented: {
            itemSelectedBg: "#8a3cbf",
            itemSelectedColor: "#fff",
            itemBg: "#fff",
            trackPadding: "12",
          },
        },
      }}
    >
      <Segmented
        id="asset-table-view"
        //   className={segmentedStyles.basicOne}
        options={[
          { value: "fmea", label: "FMEA" },
          { value: "tips", label: "Inpsections from Video" },
          // { value: "local", label: "Videos in local language" },
        ]}
        onChange={(e) => setViewType(e)}
        value={viewType}
      />
    </ConfigProvider>
  );
};

export default TypeSegemnted;
