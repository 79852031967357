import {
  Button,
  Card,
  Col,
  Flex,
  Image,
  Row,
  Skeleton,
  Tag,
  Typography,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import CustomCard from "../SOP/CustomCard";
import asset1 from "../../assets/images/asset1.png";
import { CloseOutlined, UploadOutlined } from "@ant-design/icons";
import axios from "axios";

const AssetInfo = ({ assetDetails, setAssetDoc, assetDoc }) => {
  const [uploading, setUploading] = useState(false);
  const props = {
    name: "file",
    multiple: false,
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const onChange = async (info) => {
    setUploading(true);
    if (info.file.status === "done") {
      try {
        const res = await axios.post(
          `https://hackathon.beyondscale.tech/api/upload?name=${info.file.uid}-${info.file.name}`
        );
        if (res) {
          setAssetDoc(res?.data?.pre_signed_url);
          await uploadToS3(res?.data?.signed_url, info);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setUploading(false);
      }
    }
  };

  const uploadToS3 = async (url, info) => {
    info.file.name = `${info.file.uid}-${info.file.name}`;
    console.log(info);
    try {
      await axios({
        method: "PUT",
        url: url,
        data: info.file.originFileObj,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (assetDetails?.label == "Vertical Injection Molding Machine") {
      setAssetDoc(
        "https://hackathon-objects.s3.amazonaws.com/SOP-Minijector--Injection-Molding-Machine-_Mar-2015_--1-+1.pdf"
      );
    } else {
      setAssetDoc("");
    }
  }, [assetDetails]);

  const renameFile = (url) => {
    const fileNameWithParams = url.substring(url.lastIndexOf("/") + 1); // Extract file name with query params
    const fileName = fileNameWithParams.split("?")[0]; // Remove query params from file name
    return fileName;
  };

  return (
    <CustomCard
      title={
        <Typography className="h6" style={{ textAlign: "left" }}>
          Asset Details
        </Typography>
      }
      style={{
        minHeight: "400px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Row style={{ marginBlock: "24px" }}>
        <Col span={12}>
          <Flex
            style={{
              width: "100%",
              justifyContent: "center",
              padding: "12px",
              gap: "12px",
            }}
          >
            <Typography className={"h6"}>
              <span style={{ minWidth: "200px" }}> Make</span> :{" "}
              {assetDetails?.make}
            </Typography>
          </Flex>
          <Flex
            style={{
              width: "100%",
              justifyContent: "center",
              padding: "12px",
              gap: "12px",
            }}
          >
            <Typography className={"h6"}>
              <span style={{ minWidth: "200px" }}> Model :</span>{" "}
              {assetDetails?.model}{" "}
            </Typography>
          </Flex>
          <Flex
            style={{
              width: "100%",
              justifyContent: "center",
              padding: "12px",
              gap: "12px",
            }}
          >
            <Typography className={"h6"}>
              <span style={{ minWidth: "200px" }}> Status : </span>
              {assetDetails?.status}
            </Typography>
          </Flex>
          <Flex
            style={{
              width: "100%",
              justifyContent: "center",
              padding: "12px",
              gap: "12px",
            }}
          >
            <Typography className={"h6"}>
              <span style={{ minWidth: "200px" }}> Year of Installation :</span>{" "}
              {assetDetails?.year}
            </Typography>
          </Flex>
          {/* <Flex
            style={{
              width: "100%",
              justifyContent: "flex-start",
              padding: "12px",
              gap: "12px",
            }}
          >
            <Typography className={"h6"}>
              <span style={{ minWidth: "200px" }}> Specifications :</span>{" "}
              {assetDetails?.year}
            </Typography>
          </Flex> */}
          {/* <Flex
            style={{
              flexDirection: "column",
              padding: "12px",
              gap: "12px",
              width: "100%",
              justifyContent: "center",
              textAlign: "left",
              alignItems: "center",
            }}
          >
            <Upload
              showUploadList={false}
              maxCount={1}
              className="assetsUpload"
              {...props}
              accept={[".pdf"]}
              loading={uploading}
              customRequest={dummyRequest}
              onChange={(info) => onChange(info)}
            >
              <Button
                size="large"
                loading={uploading}
                type="primary"
                style={{ width: "100%" }}
                disabled={assetDoc}
              >
                {"Upload  documents"}
                <UploadOutlined />
              </Button>
            </Upload>
            {assetDoc && (
              <div className={"fileListItem"}>
                <a href={assetDoc} target="_blank" style={{ color: "#101828" }}>
                  <span>{renameFile(assetDoc)}</span>
                </a>

                <CloseOutlined
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setAssetDoc("");
                  }}
                />
              </div>
            )}
          </Flex> */}
        </Col>
        <Col span={12}>
          <div style={{ minWidth: "30vw" }}>
            <Image
              style={{ width: "100%", height: "auto" }}
              src={assetDetails?.image}
            />
          </div>
        </Col>
      </Row>
    </CustomCard>
  );
};

export default AssetInfo;
