import { useState, useEffect } from "react";
import "./App.css";
import { Button, Col, ConfigProvider, Row, Select, Typography } from "antd";
import AssetInfo from "./Components/Asset/AssetInfo";
import PartsInfo from "./Components/Asset/PartsInfo";
import FMEA from "./Components/Asset/FMEA";
import SOP from "./Components/SOP";
import axios from "axios";
// import Tips from "./Components/Tips";
import TypeSegemnted from "./Components/TypeSegemented";
import FMEAOV from "./Components/FMEAOV";
import Tips from "./Components/Tips";

function App() {
  const [viewType, setViewType] = useState("fmea");

  return (
    <ConfigProvider
      theme={{ token: { colorPrimary: "#8a3cbf" }, components: {} }}
    >
      <div className="App" style={{ padding: "24px 72px" }}>
        {/* <Tips /> */}
        <Row gutter={[24, 24]}>
          <Col span={20} offset={2}>
            <Typography.Title>Berlin Cements Inc.</Typography.Title>
          </Col>
          <Col span={24}>
            <TypeSegemnted viewType={viewType} setViewType={setViewType} />
          </Col>
          {viewType === "tips" ? (
            <Col span={20} offset={2}>
              <Tips />
            </Col>
          ) : (
            <FMEAOV />
          )}
        </Row>
      </div>
    </ConfigProvider>
  );
}

export default App;
