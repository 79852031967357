import { Button, Col, Select } from "antd";
import React, { useEffect, useState } from "react";
import AssetInfo from "../Asset/AssetInfo";
import stringmillingroll from "../../../src/assets/images/stripmillingroll.png";
import axios from "axios";
import FMEA from "../Asset/FMEA";

const FMEAOV = () => {
  const [assets, setAssets] = useState([]);
  const [asset, setAsset] = useState({});
  const [assetParts, setAssetParts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [assetDoc, setAssetDoc] = useState("");

  const getAssets = async () => {
    try {
      // const resp = await axios.get(
      //   `https://dev.getbolt.in/api/asset/assets/locations?page=1`
      // );
      setAssets([
        {
          label: "Strip Rolling mill",
          value: "Strip Rolling mill",
          make: "Danieli",
          model: "QSP",
          year: "2010",
          status: "Online",
          image: stringmillingroll,
        },
        // {
        //   label: "Bucket Elevator",
        //   value: "Bucket Elevator",
        //   make: "Tongshengjia",
        //   model: "FA1267",
        //   year: "2010",
        //   status: "Online",
        //   image: stringmillingroll,
        // },
      ]);
      // setAssets(resp?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAssets();
  }, []);
  const generateFMEA = async () => {
    setLoading(true);
    const token = `eyJhbGciOiJSUzI1NiIsImtpZCI6IjA4YmY1YzM3NzJkZDRlN2E3MjdhMTAxYmY1MjBmNjU3NWNhYzMyNmYiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiNjE4MTA0NzA4MDU0LTlyOXMxYzRhbGczNmVybGl1Y2hvOXQ1Mm4zMm42ZGdxLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiNjE4MTA0NzA4MDU0LTlyOXMxYzRhbGczNmVybGl1Y2hvOXQ1Mm4zMm42ZGdxLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTA1MDQzNzg3OTY2MDQwODkwODI5IiwiZW1haWwiOiJub29yc2hhaWsudGVzdGluZ0BnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiYXRfaGFzaCI6IkRxbEV0cU5RNHlDdnNUSTMzWElwblEiLCJuYmYiOjE3MTAzMzE3OTMsImlhdCI6MTcxMDMzMjA5MywiZXhwIjoxNzEwMzM1NjkzLCJqdGkiOiJhMzAxNjgwNjI3ZmRiNWUzMzI3ZWY0OGY5ZTNiMWIxOWQ4NzA0ODY0In0.ozqgApQHDusj2OvzRYTcOMhbVv1J8-T1EBhYiM4woy5jtft5NUza4DZztzraJshjyWVTlILystUZNzct6jXRMS7z7IrUZNGOhAH5pWdOUsndbwdqnfbjRMqwJ-qg1G4Z6aNJSOqmnmmfuExmByaVdKYL8iq5a8WyhCA75sAIJYp06INGpmoSHsJjtKNy8ll2jMo76oONzBi2G2B5a22wMja7nbli-g1lMeMNEF0lZXUPGIYpMgQK9ptMeJ9tKJ-XzM2IimEgI_-2B18z12VpmwwtC3QkYXtddwCo9TP0xp8ExonuoG-8JjVgFaUessn9gPCfsCStb51k5lo8nZbqjg`;
    try {
      if (asset) {
        let data = {
          type: "fmea",
          make: asset?.make,
          model: asset?.model,
          asset_name: asset?.label,
        };

        const resp = await axios.post(
          `https://dev.getbolt.in/cmms-api/generate-fmea/`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              // Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        setAssetParts(resp?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Col span={20} offset={2}>
        <Select
          style={{ width: "400px", textAlign: "left" }}
          placeholder="Select asset"
          options={assets}
          onChange={(e, v) => setAsset(v)}
        />
      </Col>
      {Object.keys(asset)?.length > 0 && (
        <>
          <Col span={20} offset={2}>
            <AssetInfo
              assetDoc={assetDoc}
              assetDetails={asset}
              setAssetDoc={setAssetDoc}
            />
          </Col>
          {assetParts?.length > 0 ? (
            <>
              {/* <Col span={20} offset={2}>
                  <PartsInfo assetParts={assetParts} assetDetails={asset} />
                </Col> */}
              <Col span={20} offset={2}>
                <FMEA assetDetails={asset} assetParts={assetParts} />
              </Col>
            </>
          ) : (
            <Col span={20} offset={2}>
              <Button
                type="primary"
                size="large"
                onClick={generateFMEA}
                loading={loading}
                loadinText=" Finding Asset Parts & Risk Analysis..."
              >
                Find Asset Parts & Risk Analysis
              </Button>
            </Col>
          )}
          {/* <Col span={20} offset={2}>
              <SOP assetDoc={assetDoc} assetDetails={asset} />
            </Col> */}
        </>
      )}
    </>
  );
};

export default FMEAOV;
