import { Button, ConfigProvider, Flex, Form, Input } from "antd";
import axios from "axios";
import React, { useState } from "react";
import VideoPlayer from "./VideoPlayer";

const Tips = () => {
  const [tips, setTips] = useState([]);
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const getTips = async () => {
    try {
      setLoading(true);
      const resp = await axios.post(
        `https://dev.getbolt.in/cmms-api/get-video-tips/`,
        { source: "youtube", url: url },
        {
          Authorization:
            "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NjVkYTk0NjE3Njg1OTgxYzg0Yjk1MzMiLCJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZW1haWwiOiJzbnZycjUyQGdtYWlsLmNvbSIsImlhdCI6MTcxNzU4MDQ0NCwiZXhwIjoxNzE3NjY2ODQ0fQ.MhQTqy2difdE4sQAd4PSSmP-qbmMsGHkh1uu4n7LOVgJbUa_GzOAip3zQQF1pB2iAjfJBcwG_S38WnjxT7cYZBOLBYUHrG1N23ALPHmh_qzdLDOTyK4LstfvwRqXmcM3AVZKACV-e9r6eeOGeTP_2wjDp88pIPOVkI4uKRLXetJjGjSp6FfowojIrbl7hHLuOI5Y0lIZURnPMM0-uqp757jVtT1go8Jjqyto0lyqNcjqT2gnPKYTiMWodi4trhrtdOac23lTsK-TxSrNTpRQGMsQ2vbQp4UXde0SwAYE7XJGIG-TUNEgRFZYfh9J7GSX5UwxNqwKo7p045WIx-zmIr6CUM1w60bE5PUpZpRraebGSpEvtDqMv8EUo6OvyarIExqknYi24ir6tZZl5B_9Vxf7Dk9pPA049iWw6TJHeRFn_Q7HAKYTUCnPphWmvPqiTGoL4r_tjLGm44_iqdUdpalm5M4lWavH7dffUpkY-PQBASRL9U4GFD7b4gnhP2pRQ-SpDHZhH9ew75J9lq34fmM2pc1gHdGLxtTEMP6BMi0LRUUCtwCnZEp8TeRtQThsiYPp-MJWKkGd5gP3fa182dycWG-swJshME9wL890xFqHGCEgM8S44dNU-Yj6XA5gao_YRaeGeCAvGlqm6m2dp04Hwe1-DjjwkexZytBMJQ8",
        }
      );
      setTips(resp?.data);

      // setTips([
      //   "Identify if the fault is mechanical or electrical.",
      //   "Check the indicators to identify the source of the fault.",
      //   "Inspect switches and sensors for proper functioning.",
      //   "Check for oil flow alarms and ensure proper oil circulation.",
      //   "Monitor oil return levels in the tank.",
      //   "Check the strainer for clogging or contamination.",
      //   "Inspect couplings between the pump and motor for damage or looseness.",
      // ]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div style={{ textAlign: "center" }}>
      <div style={{ width: 400, margin: "auto", marginBlock: "24px" }}>
        {url && <VideoPlayer url={url} height={250} width={400} />}
      </div>
      {[...(tips?.tips || []), ...(tips?.insights || [])]?.length > 0 ? (
        <div style={{ width: "60vw", margin: "auto" }}>
          {[...(tips?.tips || []), ...(tips?.insights || [])]?.map(
            (tip, index) => (
              <ul key={index}>
                <li style={{ textAlign: "left" }}>{tip}</li>
              </ul>
            )
          )}
        </div>
      ) : (
        <Flex justify="center" align="center" gap={"12px"}>
          <Input
            style={{ width: "25vw" }}
            placeholder="Enter youtube url"
            onChange={(e) => setUrl(e.target.value)}
          />

          <Button type="primary" loading={loading} onClick={getTips}>
            {loading ? "Generating..." : "Generate insights from video"}
          </Button>
        </Flex>
      )}
    </div>
  );
};

export default Tips;
